define("bottomline-execution/components/trip-stop-storage-line", ["exports", "@ember/object/computed", "@ember/component", "@ember/object", "bottomline-execution/mixins/app-state"], function (_exports, _computed, _component, _object, _appState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_appState.default, {
    tagName: 'tr',
    classNames: ['trip-stop-storage'],
    classNameBindings: ['isSelected'],
    truckSettings: (0, _computed.alias)('appState.truckSettings'),
    compartments: (0, _computed.alias)('truckSettings.compartments'),
    showDensity: (0, _computed.alias)('truckSettings.show_density_on_load'),
    showAmbientLiters: (0, _computed.alias)('truckSettings.show_ambient_liters_on_load'),
    showTemperature: (0, _computed.alias)('truckSettings.show_temperature_on_load'),
    loadTicketnbPerTank: (0, _computed.alias)('truckSettings.load_ticketnb_per_tank'),
    isSelected: (0, _object.computed)('activeTripStopStorage', function () {
      return this.get('activeTripStopStorage.id') === this.get('tripStopStorage.id');
    }),
    registerBatchNumber: (0, _computed.alias)('tripStopStorage.material.registerBatchNumber'),
    registerExpiryDate: (0, _computed.alias)('tripStopStorage.material.registerExpiryDate'),
    click: function click() {
      if (this.get('compartments')) {
        var tripStopStorageId = this.get('tripStopStorage.id') || -1;
        this.get('parentController').send('selectTripStopStorage', tripStopStorageId);
      }
    }
  });

  _exports.default = _default;
});