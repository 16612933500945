define("bottomline-execution/serializers/bx/end-inventory", ["exports", "active-model-adapter", "@ember/object", "@ember/utils", "bottomline-execution/mixins/unit-of-calculation"], function (_exports, _activeModelAdapter, _object, _utils, _unitOfCalculation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _activeModelAdapter.ActiveModelSerializer.extend(_unitOfCalculation.default, {
    store: null,
    tripId: null,
    endInventoryStopId: (0, _object.computed)('tripId', function () {
      return "".concat(this.get('tripId'), ":E");
    }),
    mapResponse: function mapResponse(tripId, endInventory) {
      this.set('tripId', tripId);

      var endStopStorages = this._buildTripStopStorages(endInventory);

      var targetQuantity = this.get('unitOfCalculationQty');
      var confirmedQty = endStopStorages.reduce(function (sum, tss) {
        return sum + tss.attributes[targetQuantity];
      }, 0);

      this._unloadObsoleteTripStopStorages(); // Analogous to createEndInventoryStop() in the trip model


      var endStop = {
        id: this.get('endInventoryStopId'),
        type: 'end-inventory',
        attributes: {
          ActivityCode: 'E',
          ConfirmedSequenceNb: 1000,
          confirmedQty: confirmedQty,
          DriverRemarks: endInventory.driver_remarks,
          Odometer: endInventory.kilometer_reading,
          SequenceNb: 1000,
          state: this._getState(endInventory),
          TripID: tripId
        },
        relationships: {
          'trip': {
            data: {
              type: 'trip',
              id: tripId
            }
          },
          'trip_stop_storages': {
            data: endStopStorages.map(function (_ref) {
              var type = _ref.type,
                  id = _ref.id;
              return {
                type: type,
                id: id
              };
            })
          }
        }
      };
      return {
        tripStop: endStop,
        tripStopStorages: {
          data: endStopStorages
        }
      };
    },
    _unloadObsoleteTripStopStorages: function _unloadObsoleteTripStopStorages() {
      this.get('store').peekAll('trip-stop-storage').filterBy('TripStopID', this.get('endInventoryStopId')).toArray().invoke('unloadRecord');
    },
    _buildTripStopStorages: function _buildTripStopStorages(endInventory) {
      var _this = this;

      var compartmentInventories = endInventory.getEach('compartment_inventories').flat();
      var materialInventories = compartmentInventories.reduce(function (collector, inventory) {
        if ((0, _utils.isEmpty)(inventory.product_id)) return collector;
        if ((0, _utils.isEmpty)(collector[inventory.product_id])) collector[inventory.product_id] = 0;
        collector[inventory.product_id] += inventory.volume_in_liters;
        return collector;
      }, {});
      var targetQuantity = this.get('unitOfCalculationQty');
      return Object.keys(materialInventories).map(function (product_id) {
        var _attributes;

        return {
          id: _this.get('endInventoryStopId') + '_' + product_id,
          type: 'trip-stop-storage',
          attributes: (_attributes = {}, _defineProperty(_attributes, targetQuantity, materialInventories[product_id]), _defineProperty(_attributes, "MaterialID", product_id), _defineProperty(_attributes, "TripStopID", _this.get('endInventoryStopId')), _attributes)
        };
      });
    },
    _getState: function _getState(endInventory) {
      if ((0, _utils.isPresent)(endInventory)) {
        return 'finished';
      } else {
        return 'planned';
      }
    },
    serialize: function serialize(snapshot) {
      var attrs = snapshot.attributes();
      return {
        kilometer_reading: attrs.Odometer,
        remarks: attrs.DriverRemarks,
        vehicle_inventories: this._mapInventories(snapshot)
      };
    },
    _mapInventories: function _mapInventories(snapshot) {
      var shift = snapshot.belongsTo('trip').belongsTo('shift');
      var inventories = shift.hasMany('truck_inventories').filter(function (t) {
        return t.record.get('isCompartmentInventory');
      }).map(function (inventory) {
        return inventory.attributes();
      });

      var vehicleIds = this._vehicleWithInventoryIds(shift, inventories);

      return vehicleIds.map(function (vehicleId) {
        var vehicleInventories = inventories.filterBy('truck_id', vehicleId);
        return {
          vehicle_id: vehicleId,
          compartment_inventories: vehicleInventories.map(function (inventory) {
            return {
              number: inventory.truck_storage_id,
              product_id: inventory.material_id,
              volume_in_liters: inventory.currentQty
            };
          })
        };
      });
    },
    _vehicleWithInventoryIds: function _vehicleWithInventoryIds(shift, inventories) {
      var vehicleIdsInShift = shift.record.get('vehicleIds');
      var vehicleIdInInventories = inventories.getEach('truck_id').compact();
      return vehicleIdsInShift.filter(function (vehicleIdInShift) {
        return vehicleIdInInventories.includes(vehicleIdInShift);
      });
    }
  });

  _exports.default = _default;
});