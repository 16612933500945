define("bottomline-execution/templates/components/numeric-custom-field", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "zoPP1pTX",
    "block": "[[[10,0],[15,0,[30,0,[\"labelStyle\"]]],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"key\"],[[30,0,[\"labelKey\"]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"],[1,[28,[35,1],null,[[\"min\",\"value\"],[0,[30,0,[\"customFieldValue\"]]]]]],[1,\"\\n\"]],[],false,[\"editable-t\",\"input-number-spinner\"]]",
    "moduleName": "bottomline-execution/templates/components/numeric-custom-field.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});