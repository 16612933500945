define("bottomline-execution/models/bx/material", ["exports", "bottomline-execution/models/material", "ember-data", "@ember/object", "@ember/object/computed"], function (_exports, _material, _emberData, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _material.default.extend({
    shipperId: attr('string'),
    customFieldDefinitions: (0, _object.computed)(function () {
      return this.get('store').peekAll('custom-field-definition');
    }),
    shipperCustomFieldDefinitions: (0, _object.computed)('customFieldDefinitions.[]', function () {
      return this.get('customFieldDefinitions').filterBy('shipperId', this.get('shipperId'));
    }),
    batchNumberCustomFieldDefinitionId: (0, _object.computed)('shipperCustomFieldDefinitions.[]', function () {
      var customFieldDefinition = this.get('shipperCustomFieldDefinitions').findBy('name', 'batch_number') || {};
      return customFieldDefinition.id;
    }),
    expiryDateCustomFieldDefinitionId: (0, _object.computed)('shipperCustomFieldDefinitions.[]', function () {
      var customFieldDefinition = this.get('shipperCustomFieldDefinitions').findBy('name', 'expiry_date') || {};
      return customFieldDefinition.id;
    }),
    registerBatchNumber: (0, _computed.notEmpty)('batchNumberCustomFieldDefinitionId'),
    registerExpiryDate: (0, _computed.notEmpty)('expiryDateCustomFieldDefinitionId')
  });

  _exports.default = _default;
});