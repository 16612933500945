define("bottomline-execution/templates/components/batch-number-field", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ilRvGESc",
    "block": "[[[8,[39,0],[[16,0,[30,0,[\"cssClassName\"]]]],[[\"@type\",\"@value\"],[\"number\",[30,0,[\"batchNumber\"]]]],null],[1,\"\\n\"]],[],false,[\"input\"]]",
    "moduleName": "bottomline-execution/templates/components/batch-number-field.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});