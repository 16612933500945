define("bottomline-execution/serializers/bx/vehicle", ["exports", "@ember/object", "@ember/service"], function (_exports, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var VehicleSerializer = _object.default.extend({
    store: (0, _service.inject)(),
    vehicleType: {
      tractor: 'truck',
      trailer: 'trailer',
      rigid: 'truck'
    },
    truckStorageSerializer: (0, _object.computed)(function () {
      return this.get('store').serializerFor('truck-storage');
    }),
    mapResponse: function mapResponse(vehicles) {
      var _this = this;

      var includedTruckStorages = [];
      var mappedVehicles = vehicles.map(function (json) {
        var vehicleType = _this.get('vehicleType')[json.type];

        if (json.type != 'tractor') {
          var normalizedTruckStorages = _this.get('truckStorageSerializer').mapResponse(vehicleType, json.compartments);

          includedTruckStorages.push(normalizedTruckStorages);
        }

        return {
          id: json.id,
          type: vehicleType,
          attributes: {
            HaulierID: json.carrier_id,
            License: json.license_plate,
            TruckID: json.id,
            displayName: json.display_name
          }
        };
      });
      var truckStorageData = includedTruckStorages.flatMap(function (t) {
        return t.data;
      });
      return {
        data: mappedVehicles,
        included: truckStorageData
      };
    }
  });

  var _default = VehicleSerializer;
  _exports.default = _default;
});