define("bottomline-execution/components/batch-number-field", ["exports", "@ember/component", "@ember/object", "@ember/utils", "bottomline-execution/mixins/app-state"], function (_exports, _component, _object, _utils, _appState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_appState.default, {
    tagName: '',
    requiredPattern: (0, _object.computed)(function () {
      var regexpString = this.get('appState.truckSettings.batch_number_regexp');
      return new RegExp(regexpString);
    }),
    isValid: function isValid(value) {
      if ((0, _utils.isEmpty)(value)) return true;
      return this.get('requiredPattern').test("".concat(value));
    },
    cssClassName: (0, _object.computed)('batchNumber', function () {
      if (!this.isValid(this.get('batchNumber'))) return 'has-error';
    })
  });

  _exports.default = _default;
});