define("bottomline-execution/serializers/bx/truck", ["exports", "@ember/object", "@ember/service", "bottomline-execution/serializers/truck"], function (_exports, _object, _service, _truck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _truck.default.extend({
    store: (0, _service.inject)(),
    vehicleSerializer: (0, _object.computed)(function () {
      return this.get('store').serializerFor('vehicle');
    }),
    normalizeFindAllResponse: function normalizeFindAllResponse(store, primaryModelClass, payload, _id, _requestType) {
      return this.get('vehicleSerializer').mapResponse(payload.vehicles);
    }
  });

  _exports.default = _default;
});