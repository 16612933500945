define("bottomline-execution/templates/components/date-field", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "DPLYY5hq",
    "block": "[[[8,[39,0],[[16,0,[30,0,[\"cssClassName\"]]],[16,\"min\",[30,0,[\"min\"]]]],[[\"@type\",\"@value\"],[\"date\",[30,0,[\"date\"]]]],null],[1,\"\\n\"]],[],false,[\"input\"]]",
    "moduleName": "bottomline-execution/templates/components/date-field.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});