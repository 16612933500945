define("bottomline-execution/services/truck-settings-ajax", ["exports", "@ember/object", "@ember/service", "@ember/utils", "bottomline-execution/mixins/app-state"], function (_exports, _object, _service, _utils, _appState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend(_appState.default, {
    store: (0, _service.inject)(),
    ajax: (0, _service.inject)(),
    loadSettings: function loadSettings(truckId) {
      var _this = this;

      if ((0, _utils.isEmpty)(truckId)) return Promise.resolve();
      return this.get('ajax').request(this._settingsUrl(truckId)).then(function (data) {
        var settingsId = [truckId, 'settings'].join('');

        var currentSettings = _this.get('store').peekRecord('truck-setting', settingsId) || _this.get('store').createRecord('truck-setting', {
          id: settingsId
        });

        var normalizedData = _this._normalizeData(data, (0, _object.get)(currentSettings.constructor, 'attributes'));

        currentSettings.setProperties(normalizedData);
        currentSettings.save();

        _this.get('appState').notifyPropertyChange('truckSettings');

        return currentSettings;
      }).catch(function (e) {
        console.error('Error on endpoint, configuration not loaded', e);
      });
    },
    _normalizeData: function _normalizeData(data, settingsAttributes) {
      var hasProperty = Object.prototype.hasOwnProperty;
      var normalizedData = Object.keys(data).reduce(function (collector, attr) {
        var settingAttributes = settingsAttributes.get(attr) || {};
        var mapping = (0, _object.get)(settingAttributes, 'options.mapping') || {};
        collector[attr] = hasProperty.call(mapping, data[attr]) ? mapping[data[attr]] : data[attr];
        return collector;
      }, {});
      return normalizedData;
    },
    _settingsUrl: function _settingsUrl(truckId) {
      if (this.get('appState.useBeBackend')) return "/bottomline_execution_configuration?truck_id=".concat(truckId);
      var truckAdapter = this.get('store').adapterFor('truck');
      return truckAdapter.settingsUrl(truckId);
    }
  });

  _exports.default = _default;
});