define("bottomline-execution/components/date-field", ["exports", "@ember/component", "@ember/object", "@ember/utils"], function (_exports, _component, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    enforceFuture: null,
    min: (0, _object.computed)(function () {
      if (!this.enforceFuture) return null;
      return new Date().toISOString().split('T')[0];
    }),
    minimumDate: (0, _object.computed)(function () {
      return new Date(this.get('min'));
    }),
    cssClassName: (0, _object.computed)('date', function () {
      if (!this.enforceFuture || (0, _utils.isBlank)(this.get('date'))) return '';
      var userInput = new Date(this.get('date'));
      var inFuture = this.get('minimumDate') <= userInput;
      if (!inFuture) return 'has-error';
    })
  });

  _exports.default = _default;
});